<template>
    <div class="texno-khan-o">
        <div class="card-table-body ifon-add-title-block">
            <el-row :gutter="20">
                <el-col :span="24">
                    <div class="caz-blocks-sarcho-title">
                        <div
                            class="content-title d-flex align-center mr-2"
                            :class="
                                mode
                                    ? 'content__titleday'
                                    : 'content__titlenight'
                            "
                        >
                            Продажи
                        </div>
                        <div class="block-sarche">
                            <div class="header__search d-flex">
                                <crm-input
                                    :size="'small'"
                                    :className="'w100'"
                                    :class="
                                        mode ? 'input__day' : 'input__night'
                                    "
                                    v-model="search"
                                    :icon="'el-icon-search'"
                                    class="mr-3"
                                    style="width: 220px"
                                ></crm-input>
                                <el-date-picker
                                    v-model="value1"
                                    :class="
                                        mode ? 'input__day' : 'input__night'
                                    "
                                    size="small"
                                    type="date"
                                    class="mr-3"
                                    placeholder="От"
                                >
                                </el-date-picker>
                                <el-date-picker
                                    v-model="value1"
                                    size="small"
                                    :class="
                                        mode ? 'input__day' : 'input__night'
                                    "
                                    type="date"
                                    placeholder="До"
                                >
                                </el-date-picker>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <!-- end ifon-add-title-block -->

        <div class="card-table-header table-crm-smart mt-0 pt-0">
            <table
                class="table-my-code table-bordered mt-0 pt-0"
                :class="mode ? 'table__myday' : 'table__mynight'"
                v-loading="loadingData"
            >
                <thead>
                    <tr>
                        <th class="w50p" v-if="columns.id.show">
                            {{ columns.id.title }}
                        </th>

                        <th v-if="columns.Region.show">
                            {{ columns.Region.title }}
                        </th>

                        <th v-if="columns.Dealer.show">
                            {{ columns.Dealer.title }}
                        </th>

                        <th v-if="columns.Score.show">
                            {{ columns.Score.title }}
                        </th>

                        <th v-if="columns.Client.show">
                            {{ columns.Client.title }}
                        </th>

                        <th v-if="columns.Telephone.show">
                            {{ columns.Telephone.title }}
                        </th>

                        <th v-if="columns.praduct.show">
                            {{ columns.praduct.title }}
                        </th>

                        <th v-if="columns.dataContract.show">
                            {{ columns.dataContract.title }}
                        </th>

                        <th v-if="columns.data.show">
                            {{ columns.data.title }}
                        </th>

                        <th v-if="columns.settings.show">
                            {{ columns.settings.title }}
                        </th>
                    </tr>

                    <tr class="filter_sorche">
                        <th v-if="columns.id.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                size="mini"
                                v-model="filterForm.id"
                                :placeholder="columns.id.title"
                                class="id_input"
                            ></el-input>
                        </th>

                       

                        <th v-if="columns.Region.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                size="mini"
                                v-model="filterForm.Region"
                                :placeholder="columns.Region.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.Dealer.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                size="mini"
                                v-model="filterForm.Dealer"
                                :placeholder="columns.Dealer.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.Score.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                size="mini"
                                v-model="filterForm.Score"
                                :placeholder="columns.Score.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.Client.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                size="mini"
                                v-model="filterForm.Client"
                                :placeholder="columns.Client.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.Telephone.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                size="mini"
                                v-model="filterForm.Telephone"
                                :placeholder="columns.Telephone.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.praduct.show">
                            <el-input
                                :class="mode ? 'mode_1' : 'mode__2'"
                                clearable
                                size="mini"
                                v-model="filterForm.praduct"
                                :placeholder="columns.praduct.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.dataContract.show">
                            <el-date-picker
                                :class="mode ? 'mode_1' : 'mode__2'"
                                :placeholder="columns.dataContract.title"
                                v-model="filterForm.dataContract"
                                size="mini"
                            >
                            </el-date-picker>
                        </th>

                        <th v-if="columns.data.show">
                            <el-date-picker
                                :class="mode ? 'mode_1' : 'mode__2'"
                                :placeholder="columns.data.title"
                                v-model="filterForm.data"
                                size="mini"
                            >
                            </el-date-picker>
                        </th>

                        <th class="settinW" v-if="columns.settings.show"></th>
                    </tr>
                </thead>

                <transition-group name="flip-list" tag="tbody">
                    <tr
                        v-for="user in list"
                        :key="user.id"
                        class="cursor-pointer"
                    >
                        <td v-if="columns.id.show">{{ user.id }}</td>

                      

                        <td v-if="columns.Region.show">
                            {{ user.Region }}
                        </td>

                        <td v-if="columns.Dealer.show">
                            {{ user.Dealer }}
                        </td>

                        <td v-if="columns.Score.show">
                            {{ user.Score }}
                        </td>

                        <td v-if="columns.Client.show">
                            {{ user.Client }}
                        </td>

                        <td v-if="columns.Telephone.show">
                            {{ user.Telephone }}
                        </td>

                        <td v-if="columns.praduct.show">
                            <el-button
                                class="w-100"
                                icon="el-icon-right"
                                size="mini"
                                type="success"
                                @click="drawerShow = true"
                            >
                                Детали
                            </el-button>
                        </td>

                        <td v-if="columns.dataContract.show">
                            {{ user.dataContract }}
                        </td>

                        <td v-if="columns.data.show">{{ user.data }}</td>

                        <td v-if="columns.settings.show" class="settings-td">
                            <el-dropdown
                                :hide-on-click="false"
                                class="table-bottons-right"
                            >
                                <span class="el-dropdown-link more_icons">
                                    <i
                                        class="
                                            el-icon-arrow-down
                                            el-icon-more-outline
                                        "
                                    ></i>
                                </span>
                                <el-dropdown-menu
                                    slot="dropdown"
                                    class="dropdown-menumy-style"
                                >
                                    <el-dropdown-item icon="el-icon-edit">
                                        Изменить
                                    </el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-delete">
                                        Удалить
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </td>
                    </tr>
                </transition-group>
            </table>
        </div>

        <div class="app-modal app-modal__full modal-color-bg">
            <el-drawer
                :with-header="false"
                :visible.sync="drawerShow"
                size="97%"
            >
                <div>
                    <CrmShow> </CrmShow>
                </div>
            </el-drawer>
        </div>
    </div>
</template>
<script>
import CrmShow from "./components/crm-show.vue";
export default {
    name: "texnosale",
    components: {
        CrmShow,
    },
    data() {
        return {
            drawerShow: false,
            filterForm: {
                id: "",
               
                Region: "",
                Dealer: "",
                Score: "",
                Client: "",
                Telephone: "",
                praduct: "",
                dataContract: "",
                data: "",
            },
            list: [
                {
                    id: "8",
                    Region: "Ташкент",
                    Dealer: "Дилер А",
                    Score: "Магазин А",
                    Client: "Клиент 1",
                    Telephone: "+99899 1234567",
                  
                    praduct: "",
                    dataContract: "02.05.1995",
                    data: "12.12.2020",
                },
            ],
            columns: {
                id: {
                    show: true,
                    title: "№",
                    sortable: true,
                    column: "id",
                },

               
                Region: {
                    show: true,
                    title: "Регион",
                    sortable: true,
                    column: "Region",
                },

                Dealer: {
                    show: true,
                    title: "Дилер",
                    sortable: true,
                    column: "Dealer",
                },

                Score: {
                    show: true,
                    title: "Магазин",
                    sortable: true,
                    column: "Score",
                },

                Client: {
                    show: true,
                    title: "Клиент",
                    sortable: true,
                    column: "Client",
                },

                Telephone: {
                    show: true,
                    title: "Телефон",
                    sortable: true,
                    column: "Telephone",
                },

              

                praduct: {
                    show: true,
                    title: "Детали",
                    sortable: true,
                    column: "praduct",
                },

                dataContract: {
                    show: true,
                    title: "Дата создания ",
                    sortable: true,
                    column: "dataContract",
                },
                data: {
                    show: true,
                    title: "Изменено ",
                    sortable: true,
                    column: "data",
                },
                settings: {
                    show: true,
                    title: "Профиль",
                    sortable: false,
                    column: "settings",
                },
            },
        };
    },
};
</script>
