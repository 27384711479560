<template >
    <div>
        <div class="app-modal__box">
            <div class="app-modal__in">
                <div
                    class="app-modal__header d-flex f-between p-5 m-title-modal"
                >
                    <p class="large--title m-0">Услуги</p>
                    <div>
                        <crm-store-update-close
                            :permission="$options.name"
                            :button_type="'store'"
                            :loading="loadingButton"
                            @c-submit="submit(true)"
                            @c-close="close()"
                        ></crm-store-update-close>
                    </div>
                </div>
            </div>
            <!-- app-modal__header end -->
            <div class="app-modal__body p-5 pb-0">
                <div class="timeline-items__right rounded-sm w-100 p-4">
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <div>Продажа: <b> № 1 от 12.12.2020 12:00</b></div>
                        </el-col>
                        <el-col :span="6">
                            <div>Клиент: <b> Клиент 1</b></div>
                        </el-col>
                        <el-col :span="6">
                            <div>Телефон: <b>+99899 1234567 1</b></div>
                        </el-col>
                        <el-col :span="6">
                            <div>
                                Адрес:
                                <b>
                                    Ташкент, Шайхантаурский район, Улица
                                    Караташ, 11А
                                </b>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <div class="timeline-items__right rounded-sm w-100 p-4">
                    <el-table
                        :data="tableDataMaster"
                        border
                        style="width: 100%"
                    >
                        <el-table-column prop="name" label="Наименование">
                        </el-table-column>
                        <el-table-column prop="praduct" label="Товар">
                        </el-table-column>
                        <el-table-column prop="gruppa" label="Группа">
                        </el-table-column>
                        <el-table-column prop="cod" label="Серийный номер">
                        </el-table-column>
                        <el-table-column
                            prop="class"
                            label="Класс энергопотребления"
                        >
                        </el-table-column>
                        <el-table-column prop="grant" label="Гарантия">
                        </el-table-column>
                        <el-table-column
                            prop="dataSroc"
                            label="Дата  срока гарантии"
                        >
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <!-- end app-modal__body -->
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            tableDataMaster: [
                {
                    name: "Samsung RB30N4020B1/WT",
                    praduct: "Холодильник",
                    gruppa: "Бытовая техника",
                    cod: "CXZC3454CX",
                    class: "A+",
                    grant: "3 года",
                    dataSroc: "12.12.2020 12:00",
                },
                {
                    name: "Samsung RB30N4020B1/WT",
                    praduct: "Холодильник",
                    gruppa: "Бытовая техника",
                    cod: "CXZC3454CX",
                    class: "A+",
                    grant: "3 года",
                    dataSroc: "12.12.2020 12:00",
                },
                {
                    name: "Samsung RB30N4020B1/WT",
                    praduct: "Холодильник",
                    gruppa: "Бытовая техника",
                    cod: "CXZC3454CX",
                    class: "A+",
                    grant: "3 года",
                    dataSroc: "12.12.2020 12:00",
                },
                {
                    name: "Samsung RB30N4020B1/WT",
                    praduct: "Холодильник",
                    gruppa: "Бытовая техника",
                    cod: "CXZC3454CX",
                    class: "A+",
                    grant: "3 года",
                    dataSroc: "12.12.2020 12:00",
                },
                {
                    name: "Samsung RB30N4020B1/WT",
                    praduct: "Холодильник",
                    gruppa: "Бытовая техника",
                    cod: "CXZC3454CX",
                    class: "A+",
                    grant: "3 года",
                    dataSroc: "12.12.2020 12:00",
                },
                {
                    name: "Samsung RB30N4020B1/WT",
                    praduct: "Холодильник",
                    gruppa: "Бытовая техника",
                    cod: "CXZC3454CX",
                    class: "A+",
                    grant: "3 года",
                    dataSroc: "12.12.2020 12:00",
                },
                {
                    name: "Samsung RB30N4020B1/WT",
                    praduct: "Холодильник",
                    gruppa: "Бытовая техника",
                    cod: "CXZC3454CX",
                    class: "A+",
                    grant: "3 года",
                    dataSroc: "12.12.2020 12:00",
                },
            ],
        };
    },
};
</script>
